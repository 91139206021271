/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Escampa from "@components/sharedComponents/customersLogos/logos/Escampa.svg"
import Kontigo from "@components/sharedComponents/customersLogos/logos/Kontigo.svg"
import Proempresa from "@components/sharedComponents/customersLogos/logos/Proempresa.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/estrategias-para-captar-clientes-en-banco.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/experiencia-cliente-bancos.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/fidelizar-clientes-banco.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/experiencia-cliente-sector-financiero.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/telemarketing-bancario.jpg"

// logos testimonios
import ProempresaTes from "@components/sharedComponents/testimonies/images/logos/proempresa.svg"
import CulquiTes from "@components/sharedComponents/testimonies/images/logos/culqi.svg"
import KontigoTes from "@components/sharedComponents/testimonies/images/logos/kontigo.svg"
import EscampaTes from "@components/sharedComponents/testimonies/images/logos/escampa.svg"

//foto testimonio desktop
import proempresaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/proempresa.png"
import culqiDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/culqi.png"
import kontigoDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/kontigo.png"
import escampaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/escampa.png"

//foto testimonio desktop
import proempresaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/proempresa.png"
import culqiMobile from "@components/sharedComponents/testimonies/images/fotosMobile/culqi.png"
import kontigoMobile from "@components/sharedComponents/testimonies/images/fotosMobile/kontigo.png"
import escampaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/escampa.png"


//image acordeon left
import ImageAccordionLeftOne from "@components/pageIndustrias/pageBanca/images/imageAccordion/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageIndustrias/pageBanca/images/imageAccordion/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageIndustrias/pageBanca/images/imageAccordion/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageIndustrias/pageBanca/images/imageAccordion/imageFour.webp"

//iconos poteciamos operaciones
import imageConversionsOne from "@components/pageIndustrias/pageBanca/images/imageConvertions/imageOne.webp"
import imageConversionsTwo from "@components/pageIndustrias/pageBanca/images/imageConvertions/imageTwo.webp"
import imageConversionsThree from "@components/pageIndustrias/pageBanca/images/imageConvertions/imageThree.webp"
import imageConversionsFour from "@components/pageIndustrias/pageBanca/images/imageConvertions/imageFour.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageBanca.jpg"

import {TitleUnderlineStartOne, TitleUnderlineStartTwo } from "@components/pageIndustrias/pageBanca/titleUnderline/titleUnderlineStart"
import imageStartContactCenter from "@components/pageIndustrias/pageBanca/images/imageStart.webp"

import marcadores from "@components/pageIndustrias/pageBanca/images/marcadores.svg"
import telefonia from "@components/pageIndustrias/pageBanca/images/telefonia.svg"
import voz from "@components/pageIndustrias/pageBanca/images/voz.svg"


const titleStart = (
    <p>
        Crea experiencias únicas en cada <TitleUnderlineStartOne underline="proceso" /> <TitleUnderlineStartTwo underline="financiero" /> mediante, automatización, omnicanalidad e IA
    </p>
)
let data = {
    start: {
        title: titleStart,
        textbody: (
            <p>
                Desde la atención al cliente, hasta cobranzas, transforma cada punto de contacto en una experiencia positiva, mediante autoservicio con Inteligencia Artificial, workflows, integraciones a todo nivel y mucho más.
                <br /><br />
                Nuestras soluciones están diseñadas para empresas de servicios financieros que buscan no solo adaptarse, sino liderar en la era digital y brindar la seguridad necesaria a sus clientes.
            </p>
        ),
        image: imageStartContactCenter,
        textbutton: "Habla con un experto",
        link: "/hablemos/",
    },
    customersDesktop: [
        Rimac,
        Culqui,
        Alfin,
        Escampa,
        Kontigo,
        Proempresa
    ],
    customersTablet: {
        arrayOne: [Rimac, Culqui, Alfin, Escampa, Kontigo, Proempresa],
    },
    accordionRight: [
        {
            title: "Automatiza la autenticación de cuentas",
            description: "<p>Protege las cuentas de tus clientes enviando mensajes automáticos de verificación (2FA). Ya sea por llamada, SMS o WhatsApp, utiliza el canal más seguro para ellos.</p>",
            image: ImageAccordionLeftOne
        },
        {
            title: "Previene actividades sospechosas",
            description: "<p>Envía alertas de seguridad ante posibles actividades sospechosas en las cuentas de tus clientes. Además, ofrece asistencia en tiempo real para ayudarlos a tomar acciones inmediatas.</p>",
            image: ImageAccordionLeftTwo
        },
        {
            title: "Mejora la comunicación sobre seguridad",
            description: "<p>Envía mensajes masivos por correo o WhatsApp para educar a tus clientes sobre prevención de estafas, phishing y nuevas modalidades de robo cibernético.</p>",
            image: ImageAccordionLeftThree,
        },
        {
            title: "Login automatizado",
            description: "<p>Permite iniciar sesión y acceder a información de las cuentas de tus clientes desde canales digitales.</p>",
            image: ImageAccordionLeftFour
        },
    ],
    conversions: [
        {
            title: "Banca",
            img: imageConversionsOne,
            text: "<p> - Mejora las ventas de tus productos financieros en tiempo récord bajo un enfoque omnicanal. <br/> - Configura bots e IVRs con IA para atender a tus clientes sin la necesidad de contar con un agente. <br/> - Brinda mensajes automatizados que generen conversión en tus préstamos y créditos.</p>"
        },
        {
            title: "Seguros",
            img: imageConversionsTwo,
            text: "<p> - Crea recordatorios automáticos que notifiquen a los clientes sobre la renovación de sus pólizas. <br/>- Implementa un autoservicio que permita vender y brindar información sobres tus productos y programas.<br/>- Realiza seguimientos a tus prospectos por llamada o canal digital, sin perder el historial de conversación.",
        },
        {
            title: "Fintech",
            img: imageConversionsThree,
            text: "<p> - Automatiza los mensajes de verificación de cuentas en canales digitales y telefónicos. <br/> - Impulsa tus préstamos digitales personalizando la comunicación con tus leads. <br/> - Configura workflows de seguimiento a aquellos clientes potenciales indecisos. ",
        },
        {
            title: "Cobros",
            img: imageConversionsFour,
            text:
                "<p> - Envía recordatorios de cobro automático por canales como SMS, WhatsApp, correo o llamada. <br/> - Utiliza marcadores inteligentes para contactar a números únicamente contestados por voz humana. <br/> - Alerta a tus clientes sobre pagos confirmados, cambios de intereses, entre otros.",
        },
    ],

    articles: [
        {
            image: article1,
            title: "10 estrategias para captar clientes en un banco",
            textbody:
                "Conoce 10 estrategias para captar clientes en un banco y mantén su lealtad a largo plazo. ¡ENTRA AQUÍ y llévate los mejores consejos!",
            link: "https://beexcc.com/blog/estrategias-para-captar-clientes-en-banco/",
            card: true,
        },
        {
            image: article2,
            title: "9 estrategias para mejorar la experiencia del cliente en bancos",
            textbody:
                "Si buscas mejorar la experiencia del cliente para empresas bancarias, en el siguiente artículo te ayudaremos a lograrlo.",
            link: "https://beexcc.com/blog/experiencia-cliente-bancos/",
            card: true,
        },
        {
            image: article3,
            title: "4 estrategias sobre cómo fidelizar a los clientes de tu banco",
            textbody:
                "Entérate en este artículo sobre cómo fidelizar clientes en un banco para que así puedas tener más usuarios que regresarán a tu negocio.",
            link: "https://beexcc.com/blog/fidelizar-clientes-banco/",
        },
        {
            image: article4,
            title: "¿Cómo mejorar la experiencia del cliente para el sector financiero?",
            textbody:
                "Si buscas mejorar la experiencia del cliente para el sector financiero, en el siguiente artículo te ayudaremos a lograrlo.",
            link: "https://beexcc.com/blog/experiencia-cliente-sector-financiero/",
        },
        {
            image: article5,
            title: "Telemarketing bancario: Lo que debes hacer para tener éxito",
            textbody:
                "Descubre todo acerca del telemarketing bancario y lo que debes hacer para triunfar en todas tus campañas de productos financieros.",
            link: "https://beexcc.com/blog/telemarketing-bancario/",
        },
    ],
    contacts: {
        title: "Sé la empresa que genere la mejor conexión con sus clientes en el sector financiero",
        textbody: "Estás a un paso de revolucionar tus estrategias de atención al cliente, telemarketing y cobranzas. Agenda una reunión para que uno de nuestros especialistas se involucre con tus objetivos.",
        href: "/hablemos/",
        btn: "Quiero una asesoría gratuita",
    },
    footer: {
        product: "PRODUCTOS",
        productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
        productMobileOne: "Conversations",
        linkone: "/conversations/",
        productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
        productMobileTwo: "Contact Center",
        linktwo: "/contact-center/",
        productDesktopFour: "Validation: Software de validación de teléfono.",
        productMobileFour: "Validation",
        linkfour: "/validation/",

        solution: "SOLUCIONES",
        solutionOne: "Atención al cliente",
        linkfive: "/atencion-al-cliente/",
        solutionTwo: "Telemarketing",
        linksix: "/marketing-ventas/",
        solutionThree: "Contact Center",
        linkseven: "/contact-center/",
        solutionFour: "Cobranzas",
        linkeight: "/software-cobranzas/",
        solutionFive: "WhatsApp",
        linkwhatsapp: "/whatsapp-business-api/",
        linkchatbots: "/chatbots-online/",
        solutionSix: "Chatbots",
        linkinstagram: "/chatbot-instagram/",
        solutionSeven: "Instagram",
        linkwebchat: "/chat-en-vivo-web/",
        solutionEight: "WebChat",

        company: "EMPRESA",
        companyOne: "Nosotros",
        linknine: "/nosotros/",
        companyTwo: "Casos de éxito",
        linkten: "/casos-de-exito/",
        companyThree: "Blog",
        linkeleven: "https://beexcc.com/blog/",
        companyFour: "Hablemos",
        linktwelve: "/hablemos/",
        companySix: "Documentación",
        linkdocs: "https://beexcc.com/docs/",
        companyFive: "Recursos",
        linktthirteen: "/recursos/",

        blog: "BLOG",
        blogone: "¿Cómo elegir el mejor software para call center?",
        linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

        blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
        linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

        blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
        linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

        blogfour:
            "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
        linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

        blogfive: "9 características que las herramientas de telemarketing",
        linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

        masblog: "Conoce más sobre nuestro blog",
        linkmasblog: "https://beexcc.com/blog/",

        address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
        phone: "(+511) 277-4188",
        mail: "hi@beexcc.com",
        copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
        privacyPolicy: "Política de Privacidad",
        privacyPolicyHref: "/politicas-privacidad",
        cookiesPolicy: "Política de Cookies",
        cookiesPolicyHref: "/politicas-cookies",
    },
    testimonies: [
        {
            image: CulquiTes,
            title:
                "Con Beex, hemos logrado mejorar la contactabilidad con nuestros leads en +65%",
            textbody:
                "Culqi, la fintech que revoluciona la forma de hacer pagos digitales, usa Beex para automatizar sus procesos de telemarketing, por canal telefónico y WhatsApp.",
            name: "Paul Vera",
            post: "Jefe de Telemarketing",
            link: "/casos-de-exito/culqi/",
            imgDesktop: culqiDesktop,
            imgMobile: culqiMobile,
            metrics: [
                {
                    metric: "+65%",
                    descripcion: "mejoró su contactabilidad con sus leads",
                    color: "#FFB800",
                },
                {
                    metric: "x8",
                    descripcion: "aumentó sus ventas mensuales",
                    color: "#5831D8",
                },
                {
                    metric: "x3",
                    descripcion: "redujo el tiempo de recorrido de bases",
                    color: "#00CA56",
                },
            ],
        },
        {
            image: ProempresaTes,
            title:
                "Con Beex, hemos aumentado nuestras ventas de productos de crédito por canal digital en +30%",
            textbody:
                "Proempresa, financiera especializada en microfinanzas, ha aumentado en +30% sus ventas de créditos por canal digital con la ayuda de Beex y WhatsApp Business API.",
            name: "Nancy Rodriguez Siancas",
            post: "Marketing Leader",
            link: "/casos-de-exito/proempresa/",
            imgDesktop: proempresaDesktop,
            imgMobile: proempresaMobile,
            metrics: [
                {
                    metric: "+30%",
                    descripcion: "aumento de ventas digitales",
                    color: "#FFB800",
                },
                {
                    metric: "+20%",
                    descripcion: "mejoró su CSAT",
                    color: "#5831D8",
                },
            ],
        },
        {
            image: KontigoTes,
            title:
                "Con Beex, somos capaces de cerrar el 98% de las consultas en primer contacto",
            textbody:
                "Kontigo, fintech respaldada por Mastercard, automatiza su atención al cliente omnicanal, con chatbots por WhatsApp que brindan atención 24x7.",
            name: "Analucía Siguas Velásquez",
            post: "Gerente de Marketing y Producto",
            link: "/casos-de-exito/kontigo/",
            imgDesktop: kontigoDesktop,
            imgMobile: kontigoMobile,
            metrics: [
                {
                    metric: "98%",
                    descripcion: "casos cerrados en primer contacto",
                    color: "#FFB800",
                },
                {
                    metric: "<1 minuto",
                    descripcion: "tiempo de primera respuesta",
                    color: "#5831D8",
                },
                {
                    metric: "x3",
                    descripcion: "mejoró el tiempo de resolución de casos",
                    color: "#00CA56",
                },
            ],
        },
        {
            image: EscampaTes,
            title:
                "Con Beex, automatizamos nuestra atención por WhatsApp con workflows para nuestros miles de clientes al mes",
            textbody:
                "Escampa, fintech mexicana que brinda soluciones para aliviar el sobre endeudamiento, ha mejorado en +30% su CSAT con automatizaciones y envíos masivos de WhatsApp.",
            name: "Gabriel Gmora Backal",
            post: "Socio Fundador",
            link: "/casos-de-exito/escampa/",
            imgDesktop: escampaDesktop,
            imgMobile: escampaMobile,
            metrics: [
                {
                    metric: "90%",
                    descripcion: "resolución de casos en primer contacto",
                    color: "#FFB800",
                },
                {
                    metric: "+30%",
                    descripcion: "CSAT",
                    color: "#5831D8",
                },
                {
                    metric: "<1",
                    descripcion: "tiempo de primera respuesta",
                    color: "#00CA56",
                },
            ],
        },
    ],
    bannerVideo: {
        title: "¿Cómo automatizar la atención en el sector banca y seguros?",
        description: "<p>Descubre cómo la automatización está transformando la atención en el sector banca y seguros. Explora las herramientas que están cambiando la forma en que las instituciones financieras y aseguradoras interactúan con sus clientes. <br/> <br/>   Conoce los procesos que están siendo automatizados y cómo esto impacta la eficiencia operativa y la satisfacción del cliente.</p>",
        image: imageVideo
    },
    useCard: [
        {
            icon: marcadores,
            text:
                "Utiliza marcadores inteligentes para campañas de prospección y cobranzas.",
        },
        {
            icon: voz,
            text:
                "Integra canal de voz con otras herramientas como Salesforce, SAP, etc.",
        },
        {
            icon: telefonia,
            text:
                "Implementa IVRs para autoservicio, enrutamiento a agentes y canales digitales.",
        },
    ],
}

export default data
